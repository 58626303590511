import React from 'react'

import Row from 'react-bootstrap/Row'

class StateView extends React.Component {
    
    constructor(props) {
	super(props);
	this.state = {
	    step: props.step,
	}
    }
    
    componentDidUpdate(prevProps) {
	if (prevProps !== this.props) {
	    this.setState(this.props);
	}
    }
    
    render() {
	const {
	    step,
	} = this.state;
	return (
	    <Row>
		<div className="block-line">
		    <h2 className={`block-number ${step>=1?"current":""}`}>
			1
		    </h2>
		    <h4 className={`block-title ${step>=1?"current":""}`}>
			클레이튼 지갑을 연결해주세요<br/>
			<span>Connect your Klaytn Wallet</span>
		    </h4>
		    <h2 className={`block-content ${step>=1?"current":""}`}>
			{step>=1?"Connected":"Not connected"}
		    </h2>
		</div>
		<div className="block-line">
		    <h2 className={`block-number ${step>=2?"current":""}`}>
			2
		    </h2>		    
		    <h4 className={`block-title ${step>=2?"current":""}`}>
			디스코드 계정을 연결해주세요<br/>
			<span>Connect your Discord Account</span>
		    </h4>
		    <h2 className={`block-content ${step>=2?"current":""}`}>
			{step>=2?"Connected":"Not connected"}
		    </h2>
		</div>
		<div className="block-line">
		    <h2 className={`block-number ${step>=3?"current":""}`}>
			3
		    </h2>		    
		    <h4 className={`block-title ${step>=3?"current":""}`}>
			SNKRZ 홀더 인증해 주세요<br/>
			<span>Authenticate SNKRZ holder</span>
		    </h4>
		    <h2 className={`block-content ${step>=3?"current":""}`}>
			{step>=3?"Finished":"Not finished"}
		    </h2>
		</div>		
	    </Row>
	);
    }
}

export default StateView
