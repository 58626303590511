import React, { Component } from 'react'

import "./MainPage.css"

import HeaderView from './HeaderView.js'
import ButtonView from './ButtonView.js'
import StateView from './StateView.js'

import Container from 'react-bootstrap/Container'

import queryString from 'query-string';

import Caver from 'caver-js'

// klip wallet
import NewWindow from 'react-new-window'

import KlipWallet from './KlipWallet.js'
import axios from 'axios';

const A2P_API_PREPARE_URL = "https://a2a-api.klipwallet.com/v2/a2a/prepare";
const APP_NAME = "SNKRZ Holder";

const sleep = (ms) => { return new Promise(resolve=>{ setTimeout(resolve,ms) }) }
const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(window.navigator.userAgent)

class MainPage extends Component {
    
    constructor(props) {
	super(props)
	this.state = {
	    step: 0,
	    showKlipWallet: false,
	    klipAccessUrl: undefined,
	}
	this.qs = queryString.parse(window.location.search);
    }

    handleWalletConnect(walletName) {
	console.log('handleWalletConnect');
	this.connectWallet(walletName)
    }

    async connectWallet(walletName) {
	if (walletName === 'Kaikas') {
	    try {
		const accounts = await window.klaytn.enable();
		this.account = accounts[0]
		this.setState({
		    step: 1
		})
	    } catch(err) {
		alert("No kaikas wallet")
		console.log(err);
	    }
	} else if (walletName === 'Metamask') {
	    try {
		const accounts
		      = await window.ethereum.request(
			  {
			      method: 'eth_requestAccounts'
			  }
		      );
		window.ethereum.selectedAddress
		    = accounts[0];
		this.account = accounts[0];
		this.setState({
		    step: 1
		})
	    } catch(err) {
		alert("No metamask wallet")
		console.log(err);		
	    }
	} else if (walletName === 'Klip') {
	    this.setState({
		showKlipWallet: false
	    });
	    await sleep(500);
	    let address = await this.getKlipAddress();
	    this.account = address;
	    this.setState({
		step: 1
	    })
	    this.setState({
		showKlipWallet: false
	    })
	}
    }

    getKlipAccessUrl(method, request_key) {
	if (method === "QR") {
	    return `https://klipwallet.com/?target=/a2a?request_key=${request_key}`;
	}
	return `kakaotalk://klipwallet/open?url=https://klipwallet.com/?target=/a2a?request_key=${request_key}`;
    }
    
    async getKlipAddress() {
	return new Promise(async (resolve, reject) => {
	    let response = await axios.post(A2P_API_PREPARE_URL, {
		bapp: {
		    name: APP_NAME,
		},
		type: "auth"
	    });

	    console.log(response);
	    const { request_key } = response.data;
	    if (isMobile) {
		window.location.href = this.getKlipAccessUrl("deeplink", request_key);
	    } else {
		console.log("klip_access_url", this.getKlipAccessUrl("QR", request_key));
		this.setState({
		    showKlipWallet: true,
		    klipAccessUrl: this.getKlipAccessUrl("QR", request_key)
		}) 	    
	    }
	    let timerId = setInterval(() => {
		axios.get(
		    `https://a2a-api.klipwallet.com/v2/a2a/result?request_key=${request_key}`
		).then((res) => {
		    if (res.data.result) {
			console.log(`[Result] ${JSON.stringify(res.data.result)}`);
			resolve(res.data.result.klaytn_address);
			clearInterval(timerId);
		    }
		});
	    }, 3000);
	});
    }    
    
    async handleDiscordConnect() {
	const code = this.qs.code;
	if (code === undefined) return
        const CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
        const CLIENT_SECRET = process.env.REACT_APP_DISCORD_CLIENT_SECRET;
        const REDIRECT_URL = process.env.REACT_APP_DISCORD_REDIRECT_URL;

        const url = "https://discord.com/api/oauth2/token";
        const oauthResult = await fetch(url, {
            method: "POST",
            body: new URLSearchParams({
		client_id: CLIENT_ID,
		client_secret: CLIENT_SECRET,
		code: code,
		grant_type: "authorization_code",
		redirect_uri: REDIRECT_URL,
		scope: "identify",
            }),
            headers: {
		"Content-Type": "application/x-www-form-urlencoded"
            },
        });
        console.log("oauthResult", oauthResult);

        const oauthData = await oauthResult.json();
        console.log("oauthData", oauthData);

        const userResult = await fetch("https://discord.com/api/users/@me", {
            headers: {
		authorization: `${oauthData.token_type} ${oauthData.access_token}`,
            },
        });

        const userData = await userResult.json();
        console.log("userData", userData);
        this.discord_user_id = userData.id;
	this.discord_user_name = userData.username + "-" + userData.discriminator;
	if (this.discord_user_id) {
	    this.setState({
		step: 2
	    })
	}
    }

    async handleHolderCheck() {
	console.log("discord_id", this.discord_user_id);
	console.log("wallet", this.account);
	let wallet_addr = this.account;
	let discord_user_id = this.discord_user_id;
	let discord_user_name = this.discord_user_name;
        const caver = new Caver(window.klaytn);
        console.log("create caver");

        // const sig = await caver.klay.sign(1, wallet_addr, "");
        // console.log("sig", sig);

        // const v = `0x` + sig.substring(2).substring(128, 130);
        // const r = `0x` + sig.substring(2).substring(0, 64);
        // const s = `0x` + sig.substring(2).substring(64, 128);
        // const signature = [v, r, s];
        // console.log("signature", signature);

        const url = process.env.REACT_APP_DISCORD_BOT_URL + "/api_discord_connect";
        const res = await fetch(url, {
            method: "POST",
            body: JSON.stringify({
		wallet_addr: wallet_addr,
		discord_user_id: discord_user_id,
		discord_user_name: discord_user_name,
		// signature: signature,
            }),	    
	    headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json',
	    },
        });
        const resData = await res.json();
        console.log("resData", resData);
	if (resData.code === -1) {
	    alert(resData.message)
	} else {
	    if (this.discord_user_id) {
		this.setState({
		    step: 3
		})
	    }	    
	}
    }
    
    async sendPostRequest(url, body, content_type) {
	console.log(url, body, content_type);
	const res = await fetch(url, {
	    method: "POST",
	    mode : 'no-cors',
	    headers: {
		"Content-Type": content_type,
	    },
	    body: body
	});
	return res;
    }

    render() {
	const {
	    step,
	    showKlipWallet,
	    klipAccessUrl,
	} = this.state;
	return (
	    <div>
		<HeaderView/>
		<Container fluid="md">
		    <div className="main-card">
			<div href="/" className="logo"></div>
			<h2>
			    To authenticate the holder, Log in to your wallet and then connect to Discord.
			</h2>
			<StateView
			    step={step}/>
			<ButtonView
			    step={step}
			    handleWalletConnect={this.handleWalletConnect.bind(this)}
			    handleDiscordConnect={this.handleDiscordConnect.bind(this)}
			    handleHolderCheck={this.handleHolderCheck.bind(this)}/>
		    </div>
		</Container>
		{
		    showKlipWallet ?
			<NewWindow>
			    <KlipWallet
				klipAccessUrl={klipAccessUrl}/>
			</NewWindow>
			: ""
		}
	    </div>
	)
    }
};

export default MainPage
