import React from 'react';

import QRCode from 'qrcode.react'
import { Container, Alert } from "react-bootstrap"

class KlipWallet extends React.Component {
    
    constructor(props) {
	super(props);
	this.state = {
	    klipAccessUrl: props.klipAccessUrl
	};
    }
    
    render() {
	const {
	    klipAccessUrl,
	} = this.state;
	console.log("klipAccessUrl", klipAccessUrl);
	return (
	    <Container
		style={{
		    backgroundColor: "white",
		    width: "100%",
		    padding: 20,
		    display: "flex",
		    flexDirection: "column",
		    justifyContent: "center",
		    alignItems: "center",
		}}>
		<h1>Klip 지갑 연결하기</h1>
		<div className="qr-container">
		    {
			klipAccessUrl?
			    <QRCode value={klipAccessUrl} size={250} style={{ margin: "auto" }}/>
			:""
		    }
		</div>
		<p>
		    1. 카메라 앱 또는 카카오톡 열기<br/>
		    2. QR 스캔 후 로그인<br/>
		</p>
	    </Container>
	)
    }

}

export default KlipWallet;
