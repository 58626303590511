import React from 'react';

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'

import "./HeaderView.css"


class HeaderView extends React.Component {
    
    render() {
	return (
	    <Navbar className="navbar">
		<Container fluid="md">
		    <Navbar.Brand href="/" className="logo"></Navbar.Brand>
		    <Navbar.Toggle />
		</Container>
	    </Navbar>	    
	);
    }
}


export default HeaderView
