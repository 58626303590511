import './App.css';

import { Routes, Route } from "react-router-dom"

import 'bootstrap/dist/css/bootstrap.min.css'

import MainPage from './pages/MainPage.js'



function App() {
    return (
	    <div>
	    <div className="App">
	    <Routes>
            <Route path="/" element={<MainPage />} />
	    </Routes>	    
	    </div>
	    </div>
    );
}

export default App;
