import React from 'react'
import Web3 from 'web3';

import Button from 'react-bootstrap/Button'
import Modal from 'react-modal';

import Caver from 'caver-js'

const modalStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)'
    },
    content: {
	top: '50%',
	left: '50%',
	right: 'auto',
	bottom: 'auto',
	marginRight: '-50%',
	transform: 'translate(-50%, -50%)',
	padding: 0,
	borderRadius: '16px',
	overflow: 'auto',
    },
};

class ButtonView extends React.Component {
    
    constructor(props) {
	super(props);
	this.state = {
	    step: props.step,
	    handleWalletConnect: props.handleWalletConnect,
	    handleDiscordConnect: props.handleDiscordConnect,
	    handleHolderCheck: props.handleHolderCheck,
	    walletList: [],
	    isSelectorModalOpen: false,
	    selectedAccount: undefined,
	    selectedWallet: "Kaikas",
	}
    }
    
    componentDidUpdate(prevProps) {
	if (prevProps !== this.props) {
	    this.setState(this.props);
	}
    }

    componentDidMount() {
	this.initSelector();
	const that = this;
    }

    handleClick() {
	if (!this.state.isSelectorModalOpen) {
	    this.setState({
		isSelectorModalOpen: true,
	    });
	} else {
	    this.setState({
		isSelectorModalOpen: false,
	    });	    
	}
	// this.state.handleWalletConnect
    }

    async initSelector() {
	let walletList
	    = await this.getAvailableWalletList();
	let found = false;
	for (let wallet of walletList) {
	    if (wallet.account
		&& this.state.selectedWallet === wallet.name) {
		// await this.initWalletUtil(
		//     wallet.name
		// );
		found = true;
		break;
	    }
	}
	if (!found) {
	    for (let wallet of walletList) {
		if (wallet.account) {
		    // await this.initWalletUtil(
		    // 	wallet.name
		    // );
		    break;
		}
	    }	    
	}
	console.log(walletList);
	this.setState({
	    "walletList": walletList,
	});	
    }

    async getAvailableWalletList() {
	let walletList = [];
	let kaikasWallet = {
	    name: "Kaikas",
	    icon: "ic-kaikas",
	    account: undefined,
	};
	let metamaskWallet = {
	    name: "Metamask",
	    icon: "ic-metamask",
	    account: undefined,
	};
	let klipWallet = {
	    name: "Klip",
	    icon: "ic-klip",
	    account: undefined,
	};	
	let provider = localStorage.getItem('selectedProvider')
	if (provider === "Kaikas") {
	    if (window.klaytn) {
		kaikasWallet.account = window.klaytn.selectedAddress;
	    }
	} else if (provider === "Metamask") {
	    if (window.ethereum) {
		let web3 = await new Web3(window.ethereum);
		metamaskWallet.account = window.ethereum.selectedAddress;
	    }
	} else if (provider === "Klip") {
	    let address = localStorage.getItem('klip-address')
	    if (address) {
		klipWallet.account = address;
	    } else {
		localStorage.setItem('selectedProvider', '')
	    }
	} else {
	    localStorage.setItem('selectedProvider', '')
	}
	walletList.push(kaikasWallet);
	walletList.push(metamaskWallet);
	walletList.push(klipWallet);
	return walletList;
    }
    
    closeSelectorModal(name) {
	console.log(name);
	this.state.handleWalletConnect(name);
    }
    
    getButtonView(step) {
	let walletList=this.state.walletList.map((wallet,index)=>{
	    return (
		<li key={index}>
		    <div className="btn-select-wallet">
			<a href="#" onClick={this.closeSelectorModal.bind(this, wallet.name)}>
			    <span className={wallet.icon}>
			    </span>			    
			    {wallet.name}
			</a>
		    </div>
		</li>
	    );
	});
	let button = (
	    <Button
		variant="primary"
		className="btn-mint"
		onClick={this.handleClick.bind(this)}>
		Connect Wallet
		<Modal
		    isOpen={this.state.isSelectorModalOpen}
		    onRequestClose={this.state.closeSelectorModal}
		    ariaHideApp={false}
		    style={modalStyles}
		    contentLabel="Example Modal">
		    <div className="modal-wallets">
			<div className="modal-header">
			    <h3>지갑을 연결해주세요</h3>
			    <p>Connect your wallet.</p>
			</div>
			<ul className="list-wallets">
			    {walletList}
			</ul>
		    </div>
		</Modal>		
	    </Button>
	);
	if (step === 1) {
	    button = (
		<Button
		    variant="primary"
		    className="btn-mint"
		    onClick={this.state.handleDiscordConnect}>
		    Connect Discord
		</Button>
	    );
	} else if (step === 2) {
	    button = (
		<Button
		    variant="primary"
		    className="btn-mint"
		    onClick={this.state.handleHolderCheck}>
		    Authenticate SNKRZ Holder
		</Button>
	    );	    
	} else if (step === 3) {
	    button = (
		<div>
		    <h1>Finished</h1>
		</div>
	    );	    	    
	}
	return button
    }
    
    render() {
	const {
	    step,
	} = this.state;
	let button = this.getButtonView(step);
	return (
	    <div>
		{button}
	    </div>
	);
    }
}

export default ButtonView
